import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { getAppPackage } from '../transloco-root.module';

@Injectable()
export class RouteResolver {
  constructor(
    private router: Router,
    private translocoService: TranslocoService
  ) { }

  resolve(lang: string): boolean {
    this.translocoService.setActiveLang(lang ?? this.translocoService.getDefaultLang());
    if (lang && !getAppPackage()) {
      this.router.navigate([ '/404' ]);
    }

    return true;
  }
}
