import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID, ProviderToken, TransferState, makeStateKey } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  environment = makeStateKey<string>('environment');

  constructor(
    private injector: Injector,
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformServer(this.platformId)) {
      this.state.set(this.environment, this.injector.get<string>('ENVIRONMENT' as unknown as ProviderToken<string>));
    }
  }

  isLocal(): boolean {
    return this.state.get<string>(this.environment, '') === 'local';
  }

  isDev(): boolean {
    return this.state.get<string>(this.environment, '') === 'dev';
  }

  isPat(): boolean {
    return this.state.get<string>(this.environment, '') === 'pat';
  }

  isProd(): boolean {
    return this.state.get<string>(this.environment, '') === 'prod';
  }
}
