import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { I18NRoutes, getApps } from '../../config/app-properties';
import { RouteResolver } from './resolver/route.resolver';
import { RestrictLanguageGuard } from '@com-td-mplx/library';
import { SSOGuard } from './guard/sso.guard';
import { canActivateIndex } from './guard/index.guard';

const getAllAppRoutes = (): Routes => {
  const appPropertiesRoute: Routes = [];

  getApps().filter(p => p.active).forEach((pkg) => {
    pkg.i18nRoutes.forEach((i18nRoute: I18NRoutes) => {
      appPropertiesRoute.push({
        path: `:lang/${i18nRoute[Object.keys(i18nRoute)[0]]}`,
        loadChildren: async () => import(`../package/${pkg.appCode}/app.module`).then(m => {
          return m['AppModule'];
        }),
        data: {
          appCode: pkg.appCode,
          language: Object.keys(i18nRoute)[0],
          sso: pkg.sso
        },
        resolve: { 'resolved': () => { return inject(RouteResolver).resolve(Object.keys(i18nRoute)[0]); } },
        canActivateChild: [ RestrictLanguageGuard, SSOGuard ]
      });
    });
  });
  return appPropertiesRoute;
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page/index/index.module').then(m => m.IndexModule),
    canActivate: [ canActivateIndex ]
  },
  {
    path: ':lang/-',
    loadChildren: () => import('../form-renderer/form-renderer.module').then(m => m.FormRendererModule)
  },
  ...getAllAppRoutes(),
  {
    path: '**',
    loadChildren: () => import('./page/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [ RouterModule ],
  providers: [ RestrictLanguageGuard, SSOGuard ]
})
export class AppRoutingModule { }
