import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, Optional, PLATFORM_ID, TransferState, inject, makeStateKey } from '@angular/core';
import { CanActivateFn } from '@angular/router';

@Injectable()
export class IndexGuard {
  window: Window;
  environment = makeStateKey<string>('environment');

  constructor(
    private injector: Injector,
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject('Window') injectWindow: Window
  ) {
    if (isPlatformServer(this.platformId)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.state.set(this.environment, this.injector.get<string>('ENVIRONMENT' as any));
    }

    if (isPlatformBrowser(this.platformId)) {
      this.window = injectWindow ?? window;
    }
  }

  canActivate(): boolean {
    if ([ 'local' ].includes(this.state.get<string>(this.environment, ''))) {
      return true;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.window.location.href = 'https://www.td.com';
    }
    return false;
  }
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const canActivateIndex: CanActivateFn = (route, state) => {
  return inject(IndexGuard).canActivate();
};
