import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Params, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class SSOGuard implements CanActivateChild {
  session = makeStateKey<boolean>('session');
  environment = makeStateKey<string>('environment');

  constructor(
    private router: Router,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: object,
    private state: TransferState
  ) {
    if (isPlatformServer(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.state.set(this.session, this.injector.get<boolean>('SESSION' as any));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.state.set(this.environment, this.injector.get<string>('ENVIRONMENT' as any));
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (childRoute.data['sso']) {
      if (this.state.get<boolean>(this.session, false) || this.shouldUseMockData(childRoute.queryParams)) {
        return of(true);
      } else {
        this.router.navigate([ '/404' ]);
        return of(false);
      }
    } else {
      return of(true);
    }
  }

  shouldUseMockData(queryParams: Params): boolean {
    if ([ 'local', 'dev' ].includes(this.state.get<string>(this.environment, '')) && queryParams) {
      return queryParams['usemock'] === 'true';
    } else {
      return false;
    }
  }
}
