import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { filter } from 'rxjs';
import { getSupportedLanguages } from './transloco-root.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mplx-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.updateLang(this.translocoService.getActiveLang());
    this.translocoService.setAvailableLangs(getSupportedLanguages());
    this.translocoService.langChanges$.subscribe(lang => this.updateLang(lang));

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      setTimeout(() => {
        document.querySelector('main')?.focus();
      }, 0);
    });
  }

  private updateLang(lang: string): void {
    this.document.querySelector('html')?.setAttribute('lang', lang);
  }
}
