import { HttpClientModule, HttpClientXsrfModule, HttpHeaders } from '@angular/common/http';
import { NgModule, InjectionToken, makeStateKey, TransferState, inject } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloClientOptions } from '@apollo/client/core';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import { sha256 } from 'crypto-hash';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco-root.module';
import { RouteResolver } from './resolver/route.resolver';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@jsverse/transloco';
import { IndexGuard } from './guard/index.guard';

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<unknown>('apollo.state');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientXsrfModule,
    ApolloModule,
    HttpClientModule,
    AppRoutingModule,
    TranslocoRootModule,
    NoopAnimationsModule
  ],
  providers: [
    provideClientHydration(),
    IndexGuard,
    RouteResolver,
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache()
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, cache: InMemoryCache, transferState: TransferState): ApolloClientOptions<unknown> {
        const translocoService = inject(TranslocoService);
        if (transferState.hasKey<unknown>(STATE_KEY)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cache.restore(transferState.get<any>(STATE_KEY, null));
        } else {
          transferState.onSerialize(STATE_KEY, () => {
            return cache.extract();
          });
          cache.reset();
        }
        return {
          link: createPersistedQueryLink({
            sha256
          }).concat(
            httpLink.create({
              uri: '/api/graphql',
              useGETForQueries: true,
              headers: new HttpHeaders({ 'Accept-Language': translocoService.getActiveLang() })
            })
          ),
          cache
        };
      },
      deps: [ HttpLink, APOLLO_CACHE, TransferState ]
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
